import { SectionLayoutType } from '@/shared/models/Section';
import { ISectionComponentProps } from '../utils';
import TextDefault from './Layout/TextDefault';
import TextWithImage from './Layout/TextWithImage';

export default function TextContainer(props: ISectionComponentProps) {
    const { section } = props

    const commonProps = {
        title: section.name,
        button: section.content?.button,
        paragraph: section.content?.paragraph,
        buttonText: section.content?.button_text,
        buttonLink: section.content?.button_link,
        openInNewWindow: section.content?.open_in_new_window,
        alignment: section?.customize?.format?.alignment,
        mime: section?.content?.mime
    }
    if(section.layout === SectionLayoutType.IMAGE) {
        return <TextWithImage
            image={section.content.image}
            imageAlignment={section?.customize?.format?.image_alignment}
            {...commonProps}
        />
    }

    return (<TextDefault {...commonProps}/>)
}