import { makeStyles } from '@material-ui/core';

export const useTextSectionStyles = makeStyles((theme) => ({
    image: {
        objectFit: 'scale-down',
        maxWidth: '100%',
        height: 'auto',
    },
    imageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    sectionContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '8px !important'
        }
    },
    adRoot: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    table: {
        minWidth: 700
    }
}))