import { ITheme } from '@/shared/styles/MuiTheme'
import { makeStyles } from '@material-ui/core'

export const useLinkStyles = makeStyles((theme: ITheme) => ({
    preLine: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
        '& a': {
            color: `${theme.props.sectionMode.text_background} !important`
        }
    }
}))
