import { ITextWithImageProps } from '../types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextBaseLayout from '../TextBaseLayout';
import Hidden from '@material-ui/core/Hidden';
import { useTextSectionStyles } from '../styles';
import ResponsiveImageSource from '@/shared/components/Img/ResponsiveImageSource';

function TextWithImage(props: ITextWithImageProps) {
    const { image, imageAlignment, ...restProps } = props
    const elementWidth = ['left', 'right'].includes(imageAlignment) ? 6 : 12
    const classes = useTextSectionStyles()
    const media = (
        <Grid item xs={12} lg={elementWidth} xl={elementWidth} md={elementWidth} className={classes.sectionContainer}>
            <Box className={classes.imageBox}>
                <ResponsiveImageSource mime={restProps.mime} className={classes.image} src={image} alt={restProps.title} />
            </Box>
        </Grid>
    )
    return (
        <Box>
            <Grid spacing={6} container>
                <Hidden key={1} smDown>
                    {['left'].includes(imageAlignment) && media}
                </Hidden>
                <Hidden key={2} mdUp>
                    {media}
                </Hidden>
                <Grid item xs={12} lg={elementWidth} xl={elementWidth} md={elementWidth}>
                    <TextBaseLayout {...restProps} />
                </Grid>
                <Hidden smDown>
                    {imageAlignment === 'right' && media}
                </Hidden>
            </Grid>
        </Box>
    )
}

export default TextWithImage;